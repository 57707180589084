import React, {useState, useEffect, useContext} from 'react';
import {RichText} from 'prismic-reactjs';
import Layout from '@components/layout';
import SEO from '@components/seo';
import {graphql} from 'gatsby';
import {TimelapseType} from 'src/models/Timelapse.type';
import EdmusContext from '@components/application.provider';
import Video from '@components/video';
import useLabels from '@hooks/useLabels';

export const query = graphql`
  {
    prismic {
      allTimelapsePages {
        edges {
          node {
            _meta {
              lang
            }
            text
            title
          }
        }
      }
      allTimelapsHistorys {
        edges {
          node {
            _meta {
              lang
            }
            date
            image
            name
            text
            title
            url
          }
        }
      }
    }
  }
`;

const TimelapsesHistory = (props: any) => {
  const {language} = useContext(EdmusContext);
  const doc: any = props.data.prismic.allTimelapsePages.edges.find(
    (docs: any) => docs.node._meta.lang.slice(0, 2) === language
  );
  const [timelapses, setTimelapses] = useState<TimelapseType[]>([]);
  const SIZE_OF_ELEMENTS = 3;
  const [nbOfElements, setNbOfElements] = useState<number>(SIZE_OF_ELEMENTS);
  const getTimelapses = () => {
    setNbOfElements(SIZE_OF_ELEMENTS);
    return props.data.prismic.allTimelapsHistorys.edges.filter(
      (timelapse: TimelapseType) =>
        timelapse.node._meta.lang.slice(0, 2) === language
    );
  };

  const seeMoreTimelapses = () => {
    setNbOfElements(nbOfElements + SIZE_OF_ELEMENTS);
  };

  useEffect(() => {
    const freshTimelapses = getTimelapses();
    setTimelapses(freshTimelapses);
  }, [language]);

  if (!timelapses) return null;
  const {labels} = useLabels();
  return (
    <Layout>
      <div className='timelapses-history'>
        <SEO title='Historique des timelapses du Château Edmus' />
        <div className='space-bottom-30 space-top-20'>
          <div className='container sub-container text-center'>
            <div className='space-bottom-30'>
              {RichText.render(doc.node.text)}
            </div>
          </div>
        </div>

        <div className=' timelaps-bar-container'>
          <iframe
            src={`https://my.tikee.io/time_lapses/5641/photo_gallery/cb1cc5ae-ef5f-462f-881d-8d8cef3bda2d?lang=${language}&default_tab=immersive`}
            title='timelaps château Edmus'
            style={{width: '100%', height: '100%', border: 'none'}}
          ></iframe>
        </div>

        <div className='space-bottom-30'>
          <div className='container sub-container text-center'>
            <h3 className='space-bottom-30'>{labels.TIMELAPS_ARCHIVES}</h3>
            <div className='columns '>
              {timelapses &&
                timelapses.slice(0, nbOfElements).map((t, key) => (
                  <div
                    key={key}
                    className='column col-4 col-md-12 col-sm-12 timelapses-container space-bottom-20'
                  >
                    <Video
                      className='video-timelapse-display space-bottom-10'
                      video={t.node.url}
                      image={t.node.image}
                    />
                    <div className='col full-width text-left'>
                      <div className='font-open-sans-semi-bold font-07'>
                        {t.node.title}
                      </div>
                      <div className='font-open-sans-semi-bold'>
                        {t.node.text}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {nbOfElements < timelapses.length && (
              <div className='columns space-top-30'>
                <div className='column col-4 col-sm-12 col-mx-auto text-center'>
                  <button
                    type='button'
                    className='more-button'
                    onClick={seeMoreTimelapses}
                  >
                    <i className='icon icon-2x icon-plus' />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TimelapsesHistory;
